const facilitatorUiTexts = {
	noCourses: {
		da: 'Du er ikke facilitator for nogen kurser.',
		en: 'You are not a facilitator of any courses.',
		se: `Du är inte facilitator av några kurser.`,
		de: `Sie sind kein Kursleiter.`,
		pl: `Nie jesteś animatorem żadnych kursów.`,
		ro: `Nu sunteți un facilitator al niciunui curs.`,
		ua: `Ви не фасилітатор жодних курсів.`,
	},
	selectCourse: {
		da: 'Vælg kursus',
		en: 'Select course',
		se: 'Välj kurs',
		de: 'Kurs auswählen',
		pl: 'Wybierz kurs',
		ro: 'Selectați cursul',
		ua: 'Виберіть курс',
	},
	selectAnotherCourse: {
		da: 'Vælg andet kursus',
		en: 'Choose another course',
		se: `Välj en annan kurs`,
		de: `Einen anderen Kurs wählen`,
		pl: `Wybierz inny kurs`,
		ro: `Alege alt curs`,
		ua: `Виберіть інший курс`,
	},
	switchDepartment: {
		da: 'Skift %department%',
		en: 'Switch %department%',
		se: `Ändra %department%`,
		de: `%department% wechseln`,
		pl: `Zmień %department%`,
		ro: `Schimba %department%`,
		ua: `комутаційний %department%`,
	},
	settings: {
		title: {
			da: 'Settings',
			en: 'Settings',
			se: `Inställningar`,
			de: `Einstellungen`,
			pl: `Ustawienia`,
			ro: `Setări`,
			ua: `Налаштування`,
		},
		selectLanguage: {
			da: 'Vælg sprog',
			en: 'Select language',
			se: `Välj språk`,
			de: `Sprache wählen`,
			pl: `Wybierz język`,
			ro: `Selecteaza limba`,
			ua: `Оберіть мову`,
		}
	},

	allDepartments: {
		da: 'Alle %departments%',
		en: 'All %departments%',
		se: `Alla %departments%`,
		de: `Alle %departments%`,
		pl: `Wszystkie %departments%`,
		ro: `Toate %departments%`,
		ua: `Всі %departments%`,
	},

	showInactivePlayers: {
		da: 'Vis inaktive spillere',
		en: 'Show inactive players',
		se: `Visa inaktiva spelare`,
		de: `Inaktive Spieler anzeigen`,
		pl: `Pokaż nieaktywnych graczy`,
		ro: `Afișați jucătorii inactivi`,
		ua: `Показати неактивних гравців`,
	},
	changePlayerId: {
		da: 'Omdøb',
		en: 'Rename',
		se: `Döp om`,
		de: `Umbenennen`,
		pl: `Przemianować`,
		ro: `Redenumiți`,
		ua: `Перейменувати`,
	},
	deactivatePlayer: {
		da: 'Deaktiver',
		en: 'Deactivate',
		se: `Avaktivera`,
		de: `Deaktivieren`,
		pl: `Dezaktywować`,
		ro: `Dezactivați`,
		ua: `Дезактивувати`,
	},
	activatePlayer: {
		da: 'Aktiver',
		en: 'Activate',
		se: `Aktivera`,
		de: `Aktivieren`,
		pl: `Aktywuj`,
		ro: `Activati`,
		ua: `активувати`,
	},

	noEnoughSurveyResponses: {
		da: 'Der er ikke nok svar til at vise resultatet endnu.',
		en: 'There are not enough responses yet to show the result.',
		se: `Det finns inte tillräckligt med svar ännu för att visa resultatet.`,
		de: `Es liegen noch nicht genügend Antworten vor, um das Ergebnis anzuzeigen.`,
		pl: `Nie ma jeszcze wystarczającej liczby odpowiedzi, aby wyświetlić wynik.`,
		ro: `Nu există încă suficiente răspunsuri pentru a arăta rezultatul.`,
		ua: `Ще недостатньо відповідей, щоб показати результат.`,
	},

	popupImportPlayers: {
		title: {
			da: 'Importér medarbejdere',
			en: 'Import employees',
			se: `Importera anställda`,
			de: `Mitarbeiter importieren`,
			pl: `Pracownicy importowi`,
			ro: `Import angajați`,
			ua: `Імпорт працівників`,
		},
		chooseFileType: {
			da: 'Vælg filtype',
			en: 'Choose file type',
			se: `Välj filtyp`,
			de: `Dateityp auswählen`,
			pl: `Wybierz typ pliku`,
			ro: `Alegeți tipul fișierului`,
			ua: `Виберіть тип файлу`,
		},
		columnNumbers: {
			da: 'Kolonne-numre',
			en: 'Column numbers',
			se: `Kolumnnummer`,
			de: `Spaltennummern`,
			pl: `Numery kolumn`,
			ro: `Numerele coloanelor`,
			ua: `Номери колонок`,
		},
		chooseFile: {
			da: 'Vælg fil',
			en: 'Choose file',
			se: `Välj fil`,
			de: `Datei wählen`,
			pl: `Wybierz plik`,
			ro: `Alege fișierul`,
			ua: `Виберіть файл`,
		},
		newPlayers: {
			da: 'Nye spillere',
			en: 'New players',
			se: `Nya spelare`,
			de: `Neue Spieler`,
			pl: `Nowi gracze`,
			ro: `Jucători noi`,
			ua: `Нові гравці`,
		},
		existingPlayersUpdateInfo: {
			da: 'Eksisterende spillere, opdater info',
			en: 'Existing players, update info',
			se: `Befintliga spelare, uppdatera info`,
			de: `Vorhandene Spieler, Info aktualisieren`,
			pl: `Istniejący gracze, zaktualizuj info`,
			ro: `Jucători existenți, actualizați info`,
			ua: `Існуючі гравці, оновіть info`,
		},
		invalidPlayers: {
			da: 'Ugyldige spillere',
			en: 'Invalid players',
			se: `Ogiltiga spelare`,
			de: `Ungültige Spieler`,
			pl: `Nieprawidłowi gracze`,
			ro: `Jucători invalidi`,
			ua: `Недійсні гравці`,
		},
		invalidRows: {
			da: 'Ugyldige rækker',
			en: 'Invalid rows',
			se: 'Ogiltiga rader',
			de: 'Ungültige Zeilen',
			pl: 'Nieprawidłowe wiersze',
			ro: 'Rânduri nevalide',
			ua: 'Недійсні рядки'
		},
		content: {
			da: 'Indhold',
			en: 'Content',
			se: 'Innehåll',
			de: 'Inhalt',
			pl: 'Treść',
			ro: 'Conţinut',
			ua: 'Зміст'
		},
		playerIsInAnotherDepartment: {
			da: 'Spilleren hører til <b>%departmentName%</b>',
			en: 'The player is in <b>%departmentName%</b>',
			se: `Spelaren är med <b>%departmentName%</b>`,
			de: `Der Spieler ist in <b>%departmentName%</b>`,
			pl: `Gracz jest w <b>%departmentName%</b>`,
			ro: `Jucătorul este în <b>%departmentName%</b>`,
			ua: `Гравець увійшов <b>%departmentName%</b>`,
		},
		import: {
			da: 'Importer',
			en: 'Import',
			se: `Importera`,
			de: `Importieren`,
			pl: `Importować`,
			ro: `Import`,
			ua: `Імпорт`,
		},
		updatedPlayers: {
			da: 'Opdaterede spillere',
			en: 'Updated players',
			se: `Uppdaterade spelare`,
			de: `Aktualisierte Spieler`,
			pl: `Zaktualizowani gracze`,
			ro: `Jucători actualizați`,
			ua: `Оновлені плеєри`,
		},
		nothingToImport: {
			da: 'Du har ikke valgt nogen spillere',
			en: 'You have not selected any players',
			se: `Du har inte valt några spelare`,
			de: `Du hast keine Spieler ausgewählt`,
			pl: `Nie wybrałeś żadnego gracza`,
			ro: `Nu ai selectat niciun jucător`,
			ua: `Ви не вибрали жодного гравця`,
		},
		errorMessage: {
			da: 'Fejlbesked',
			en: 'Error message',
			se: `Felmeddelande`,
			de: `Fehlermeldung`,
			pl: `Komunikat o błędzie`,
			ro: `Mesaj de eroare`,
			ua: `Повідомлення про помилку`,
		},
		feedback: {
			da: 'Feedback',
			en: 'Feedback',
			se: `Respons`,
			de: `Rückmeldung`,
			pl: `Informacja zwrotna`,
			ro: `Părere`,
			ua: `Зворотній зв'язок`,
		}
	},
	
	popupQR: {
		QRPopupHeaderTitle: {
			da: 'Kom i gang med spillet',
			en: 'Start playing',
			se: `Kom igång med spelet`,
			de: `Beginnen Sie mit dem Spiel`,
			pl: `Rozpocznij grę`,
			ro: `Începeți cu jocul`,
			ua: `Почніть гру`,
		},
		QRPopupHeaderSubtitle: {
			da: 'Gå til denne URL og log ind med dit medarbejder nummer.',
			en: 'Navigate to this URL and login with your employee ID.',
			se: `Navigera till denna URL och logga in med ditt anställd-ID.`,
			de: `Navigieren Sie zu dieser URL und melden Sie sich mit Ihrer Mitarbeiter-ID an.`,
			pl: `Przejdź do tego adresu URL i zaloguj się przy użyciu swojego identyfikatora pracownika.`,
			ro: `Navigați la această adresă URL și conectați-vă cu ID-ul de angajat.`,
			ua: `Перейдіть за цією URL-адресою та увійдіть за допомогою свого ідентифікатора працівника.`,
		},
		QRWindowHeader: {
			da: 'Scan QR-Kode',
			en: 'Scan QR-Code',
			se: `Skanna QR-koden`,
			de: `Scannen Sie den QR-Code`,
			pl: `Zeskanuj kod QR`,
			ro: `Scanați codul QR`,
			ua: `Відскануйте QR-код`,
		},
		goToUrl: {
			da: 'Gå til URL:',
			en: 'Go to URL:',
			se: `Gå till URL:`,
			de: `Gehen Sie zur URL:`,
			pl: `Przejdź do adresu URL:`,
			ro: `Accesați adresa URL:`,
			ua: `Перейти до URL:`,
		}
	},

	popupResetPlayer: {
		title: {
			da: `Nulstil modulgruppe`,
			en: 'Reset module group',
			se: `Återställ modulgrupp`,
			de: `Modulgruppe zurücksetzen`,
			pl: `Zresetuj grupę modułów`,
			ro: `Resetați grupul de module`,
			ua: `Скинути групу модулів`,
		},
		text: {
			da: `Vil du nulstille spillerens data for alle modulerne i %moduleGroupName%?
				<br />Dette kan ikke gøres om.`,
			en: `Do you want to reset the players data for all modules in %moduleGroupName%?
				<br />This cannot be undone.`,
			se: `Vill du återställa spelardata för alla moduler i %moduleGroupName%?
				<br />Detta kan inte ångras.`,
			de: `Möchten Sie die Spielerdaten für alle Module in %moduleGroupName% zurücksetzen?
				<br />Dies kann nicht rückgängig gemacht werden.`,
			pl: `Czy chcesz zresetować dane graczy dla wszystkich modułów w %moduleGroupName%?
				<br />Tej czynności nie można cofnąć.`,
			ro: `Doriți să resetați datele jucătorilor pentru toate modulele din %moduleGroupName%?
				<br />Acest lucru nu poate fi anulat.`,
			ua: `Бажаєте скинути дані гравців для всіх модулів у %moduleGroupName%?
				<br />Цю дію неможливо скасувати.`,
		}
	},
	popupDeletePlayer: {
		title: {
			da: 'Slet spiller',
			en: 'Delete player',
			se: `Ta bort spelare`,
			de: `Spieler löschen`,
			pl: `Usuń gracza`,
			ro: `Șterge playerul`,
			ua: `Видалити гравця`,
		},
		text: {
			da: `Vil du slette spilleren og al deres data?
				<br />Dette kan ikke gøres om.`,
			en: `Do you want to delete the player and all their data?
				<br />This cannot be undone.`,
			se: `Vill du radera spelaren och alla deras data?
				<br />Detta kan inte ångras.`,
			de: `Möchten Sie den Spieler und alle seine Daten löschen?
				<br />Dies kann nicht rückgängig gemacht werden.`,
			pl: `Czy chcesz usunąć gracza i wszystkie jego dane?
				<br />Tej czynności nie można cofnąć.`,
			ro: `Doriți să ștergeți playerul și toate datele acestuia?
				<br />Acest lucru nu poate fi anulat.`,
			ua: `Ви бажаєте видалити гравця та всі його дані?
				<br />Цю дію неможливо скасувати.`,
		}
	},
	popupChangePlayerId: {
		title: {
			da: 'Skift %playerIdLabel%',
			en: 'Change %playerIdLabel%',
			se: `Ändra %playerIdLabel%`,
			de: `%playerIdLabel% ändern`,
			pl: `Zmień %playerIdLabel%`,
			ro: `Schimbați %playerIdLabel%`,
			ua: `Змінити %playerIdLabel%`,
		},
		text: {
			da: 'Spilleren skal fremover bruge det nye %playerIdLabel%, når de logger ind.',
			en: 'The player should use the new %playerIdLabel% when they login in the future.',
			se: `Spelaren bör använda det nya %playerIdLabel% när de loggar in i framtiden.`,
			de: `Der Spieler sollte bei zukünftigen Anmeldungen das neue %playerIdLabel% verwenden.`,
			pl: `Gracz powinien używać nowego %playerIdLabel% przy następnym logowaniu.`,
			ro: `Jucătorul ar trebui să folosească noul %playerIdLabel% atunci când se autentifică în viitor.`,
			ua: `Гравець повинен використовувати новий %playerIdLabel% під час входу в майбутньому.`,
		},
		newPlayerId: {
			da: 'Nyt %playerIdLabel%',
			en: 'New %playerIdLabel%',
			se: `Ny %playerIdLabel%`,
			de: `Neues %playerIdLabel%`,
			pl: `Nowy %playerIdLabel%`,
			ro: `%playerIdLabel% nou`,
			ua: `Новий %playerIdLabel%`,
		},
		playerIdAlreadyInUse: {
			da: 'Der eksisterer allerede en spiller med det nye %playerIdLabel%',
			en: 'A player with the new %playerIdLabel% already exists.',
			se: `En spelare med det nya %playerIdLabel% finns redan.`,
			de: `Ein Spieler mit dem neuen %playerIdLabel% existiert bereits.`,
			pl: `Odtwarzacz z nowym %playerIdLabel% już istnieje.`,
			ro: `Un jucător cu noul %playerIdLabel% există deja.`,
			ua: `Гравець із новим %playerIdLabel% уже існує.`,
		},
	},
	popupChangePlayerInfo: {
		title: {
			da: 'Opdater spillerinfo',
			en: 'Change player info',
			se: `Ändra spelarinformation`,
			de: `Spielerinfo ändern`,
			pl: `Zmień informacje o graczu`,
			ro: `Schimbați informațiile despre jucător`,
			ua: `Змінити інформацію про гравця`,
		},
	}

};

export {
	facilitatorUiTexts
};